import Router from './router/router';
import './config/axios.ts';
import { Loading, Notifications } from './components/common';
import SubmitOverlay from './components/registration/submitOverlay.tsx';
import { setLoading } from './store/slices/loadingSlice.ts';
import { useDispatch } from 'react-redux';
import { setOverlay } from './store/slices/overlaySlice.ts';
import { resolveInitialState } from './utils/initServices.ts';

const App = () => {
  resolveInitialState;
  const dispatch = useDispatch();
  dispatch(setLoading(false));
  dispatch(setOverlay(false));
  return (
    <div className='App'>
      <Loading />
      <Notifications />
      <div className='bg'></div>
      <Router />
      <SubmitOverlay />
    </div>
  );
};

export default App;
