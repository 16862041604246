import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { Header } from '../components/common';
import Register from '../pages/register';

const Router = () => {
  return (
    <BrowserRouter>
      <Header />
      <div className='content'>
        <Routes>
          <Route path='*' element={<Register />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default Router;
