import { useNavigate } from 'react-router-dom';
import { InfoBox } from '../components/common';
import LanguageSelector from '../components/languageSelector/languageSelector';
import RegistrationForm from '../components/registration/registrationForm';
import { useTranslation } from '../utils/translate';
import { ReactComponent as MapIcon } from '../assets/svg/mapIcon.svg';

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className='register'>
      <div className='left'>
        <div className='upper'>
          <img
            className=''
            src={'/img/logo.png'}
            alt='Helenspetcare'
            onClick={() => {
              navigate('/clients');
            }}
          />
          <LanguageSelector location='inForm' />
        </div>

        <a
          className='address'
          target='_blank'
          href="https://www.google.com/maps/place/Helen's+Petcare/@59.4250106,24.7015395,17z/data=!3m1!4b1!4m6!3m5!1s0x46929313d4ae83c5:0x4c86bf8d8d60b0a1!8m2!3d59.4250106!4d24.7015395!16s%2Fg%2F11gmxlwvyq"
        >
          <div className='title'>
            {t('address')} <MapIcon className='mapIcon' />
          </div>
          <div className='value'>Mustamäe tee 29, Tallinn</div>
        </a>
        <div className='services'>
          <div className='header'>{t('sidebarTitle1')}</div>
          <div className='content'>{t('sidebarText1')}</div>
          <div className='header'>{t('sidebarTitle2')}</div>
          <div className='content'>{t('sidebarText2')}</div>
          <div className='header'>{t('sidebarTitle3')}</div>
          <div className='content'>{t('sidebarText3')}</div>
          <div className='header'>{t('sidebarTitle4')}</div>
          <div className='content'>{t('sidebarText4')}</div>
          <div className='header'>{t('sidebarTitle5')}</div>
          <div className='content'>{t('sidebarText5')}</div>
        </div>
      </div>
      <RegistrationForm />
    </div>
  );
};

export default Register;
