import { createSlice } from '@reduxjs/toolkit';

const LanguageSlice = createSlice({
  name: 'LanguageSlice',
  initialState: {
    language: 'ee'
  },
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    }
  }
});

export const { setLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
