import axios, { AxiosError } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response) {
      console.log('HTTP ERROR LMAO');
    }
    return error.response?.status;
  }
);
