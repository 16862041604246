import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../languageSelector/languageSelector';

const Header = () => {
  const navigate = useNavigate();

  // TODO: Dynamic img path
  return (
    <>
      <header className='header'>
        <div className='inner'>
          <img
            className='logo'
            src={'/img/logo.png'}
            alt='Helenspetcare'
            onClick={() => {
              navigate('/clients');
            }}
          />
          <div className='right'>
            <LanguageSelector location='header' />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
