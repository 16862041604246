import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from '../../utils/storageHandler';

const formSlice = createSlice({
  name: 'formSlice',
  initialState: {
    animalType: '',
    breed: '',
    isMixed: false,
    phoneNumber: '',
    animalAge: '',
    customerSurname: '',
    email: ''
  },
  reducers: {
    setAllFormValues(state, action) {
      state = action.payload;
    },
    setChosenAnimal(state, action) {
      state.animalType = action.payload;
      setCookie('animalType', action.payload);
    },
    setAnimalAge(state, action) {
      state.animalAge = action.payload;
      setCookie('animalAge', action.payload);
    },
    setAnimalBreed(state, action) {
      state.breed = action.payload;
      setCookie('breed', action.payload);
    },
    setCustomerPhoneNumber(state, action) {
      state.phoneNumber = action.payload;
      setCookie('phoneNumber', action.payload);
    },
    setCustomerSurname(state, action) {
      state.customerSurname = action.payload;
      setCookie('customerSurname', action.payload);
    },
    setCustomerEmail(state, action) {
      state.email = action.payload;
      setCookie('email', action.payload);
    },
    setAnimalIsMixed(state, action) {
      state.isMixed = action.payload;
      setCookie('isMixed', action.payload);
    }
  }
});

export const {
  setAllFormValues,
  setChosenAnimal,
  setAnimalAge,
  setAnimalBreed,
  setAnimalIsMixed,
  setCustomerSurname,
  setCustomerEmail,
  setCustomerPhoneNumber
} = formSlice.actions;

export default formSlice.reducer;
